import React, { useEffect, useState, useRef, useCallback } from 'react';
import { AutoComplete, Card, Col, Input, Row, Space, List, Empty, message } from 'antd';
import RouteLayout from '../components/RouteLayout';
import { SearchOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import api from '../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import settings from '../settings';
import moment from 'moment';
import roundingMethod from '../utils/roundingMethod';
import useLog from '../socketio/useLog';

const LogInfo = () => {
  const history = useHistory();

  let { id } = useParams();
  const input = useRef(null);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState(null);
  const [log, setLog] = useState(null);
  const [selectedLog, setSelectedLog] = useState({ id: id });

  const searchLogs = (plate) => {
    if (plate !== '') {
      api
        .post(`log/search`, { plate }, { params: { group: true } })
        .then((response) => {
          setLogs(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLogs(null);
    }
  };

  const getLog = useCallback(
    (loading = true) => {
      if (selectedLog && selectedLog.id) {
        setLoading(loading);
        api
          .post(`log/get`, { id: selectedLog.id })
          .then((response) => {
            setLog(response.data);
            setLoading(false);
            window.history.replaceState(
              null,
              null,
              settings.route.loginfo.replace(':id?', selectedLog.id)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [selectedLog]
  );

  useEffect(() => {
    getLog();
  }, [selectedLog, getLog]);

  const info =
    !loading && log
      ? [
          { title: 'Vrsta', value: log.type },
          { title: 'Klasa', value: log.class },
          { title: `Dužina`, value: `${log.length} ${settings.logUnit}` },
          { title: `Prečnik`, value: `${log.radius} ${settings.logUnit}` },
          { title: 'Zapremina', value: `${log.volume} ${settings.volumeUnit}` },
          log.trashed && {
            title: 'Vraćen u',
            value: moment(log.trashedAt).format(settings.fullDate),
          },
          {
            title: 'Cijena',
            value: (
              <Text type='secondary'>
                {`${roundingMethod(
                  log.volume *
                    (log.invoice.prices && log.invoice.prices[log.type]
                      ? log.invoice.prices[log.type][log.class]
                      : 0)
                )} ${settings.currency}`}
              </Text>
            ),
          },
          {
            title: 'Prijemnica',
            value: (
              <Text
                type='success'
                className='cursorPointer'
                onClick={() => history.push(settings.route.invoice.replace(':id', log.invoice.id))}>
                {log.invoice.number}
              </Text>
            ),
          },
          {
            title: 'Dobavljač',
            value: (
              <Text
                type='success'
                className='cursorPointer'
                onClick={() =>
                  history.push(settings.route.supplier.replace(':id', log.invoice.supplier.id))
                }>
                {log.invoice.supplier.name}
              </Text>
            ),
          },
          { title: 'Sastavio', value: log.invoice.composer.name },
          { title: 'Mjerio', value: log.invoice.measurer.name },
          { title: 'Datum prijema', value: moment(log.invoice.date).format(settings.date) },
          log.processed && {
            title: 'Prerada',
            value: (
              <Text
                type='success'
                className='cursorPointer'
                onClick={() => history.push(settings.route.sawlist.replace(':id', log.sawlist.id))}>
                Vidi
              </Text>
            ),
          },
          log.processedAt && {
            title: 'Vrijeme prerade',
            value: moment(log.processedAt).format(settings.fullDate),
          },
          log.processedBy && { title: 'Preradio', value: log.processedBy.name },
          { title: 'Identifikacioni kod', value: log.id },
        ]
      : [];

  const onUpdate = (event, name) => {
    if (event === 'edit') {
      message.info({
        content: `${name} je upravo izmjenio ovaj trupac.`,
        duration: 3,
      });
      getLog();
    } else if (event === 'delete') {
      message.warning({
        content: `${name} je upravo obrisao ovaj trupac!`,
        duration: 3,
      });
      history.push(settings.route.home);
    } else if (event === 'process') {
      message.info({
        content: `${name} je upravo preradio ovaj trupac!`,
        duration: 3,
      });
      getLog();
    } else if (event === 'unprocess') {
      message.warning({
        content: `${name} je upravo uklonio ovaj trupac sa liste prerade!`,
        duration: 3,
      });
      getLog();
    }
  };

  useLog(selectedLog.id || id, onUpdate);

  return (
    <RouteLayout
      title={`Informacije o trupcu${log ? `: ${log.plate}` : ''}`}
      showBG={false}
      routes={[
        {
          path: '/',
          breadcrumbName: 'Carta',
        },
        {
          breadcrumbName: 'Informacije o trupcu',
        },
      ]}>
      <Space direction='vertical' size='middle' style={{ width: '100%', overflow: 'hidden' }}>
        <Row>
          <Col flex='auto'>
            <Card style={{ width: '100%' }} size='small' title='Pretraga'>
              <AutoComplete
                style={{ width: '100%' }}
                notFoundContent='Nema trupaca sa traženom pločicom.'
                options={
                  logs &&
                  Object.keys(logs).map((type) => {
                    return {
                      label: type,
                      options: logs[type].map((log) => {
                        return {
                          key: log.id,
                          label: log.plate,
                          value: log.plate,
                        };
                      }),
                    };
                  })
                }
                onSelect={(value, option) => {
                  if (option) {
                    setSelectedLog({ id: option.key, plate: value });
                  }
                }}
                backfill
                allowClear>
                <Input
                  ref={input}
                  style={{ width: '100%' }}
                  prefix={<SearchOutlined />}
                  onChange={(e) => searchLogs(e.target.value)}
                  placeholder='Pretražite trupce po broju pločice'
                />
              </AutoComplete>
            </Card>
          </Col>
        </Row>
        <Row gutter='15'>
          <Col flex='1 1'>
            <Card style={{ width: '100%', height: '600px' }} size='small' title='Informacije'>
              {log ? (
                <List
                  style={{ height: '535px', overflow: 'auto' }}
                  key='title'
                  rowKey='title'
                  size='small'
                  itemLayout='horizontal'
                  bordered
                  loading={loading}
                  dataSource={info.filter((x) => x)}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta title={item.title} description={item.value} />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
        </Row>
      </Space>
    </RouteLayout>
  );
};

export default LogInfo;
