import { Button, Input, Form } from 'antd';
import React, { useState } from 'react';
import { UserOutlined, LockOutlined, DownloadOutlined } from '@ant-design/icons';
import { setUser } from '../utils/cookies';
import api from '../utils/api';
import cartaLogin from '../img/carta-login.png';
import cartaMobile from '../img/carta-mobile.png';
import carta from '../img/carta.png';
import { BrowserView, MobileView } from 'react-device-detect';
import download from '../img/download.png';

const Login = ({ setToken }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    api
      .post('login', values)
      .then((response) => {
        setUser(
          response.headers.authorization,
          response.data.name,
          values.username,
          response.data.role
        );
        setToken(response.headers.authorization);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.status === 'invalid_username') {
          form.setFields([
            {
              name: 'username',
              errors: ['Nepostojeće korisničko ime.'],
            },
          ]);
        } else if (error.response.data.status === 'inactive_account') {
          form.setFields([
            {
              name: 'username',
              errors: ['Vaš nalog je deaktiviran.'],
            },
          ]);
        } else {
          form.setFields([
            {
              name: 'password',
              errors: ['Netačna lozinka.'],
            },
          ]);
        }
      });
  };

  return (
    <div className='loginbg'>
      <div className='loginWrapper'>
        <BrowserView renderWithFragment>
          <img className='loginLogo' alt='Carta Systems' src={cartaLogin} />
          <div className='loginContainer'>
            <img className='loginCompanyLogo' alt='Carta' src={carta} />
            <Form
              form={form}
              name='basic'
              layout='vertical'
              requiredMark={false}
              onFinish={onFinish}>
              <Form.Item
                label='Korisničko ime'
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Unesite Vaše korisničko ime!',
                  },
                ]}>
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                label='Lozinka'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Unesite Vašu lozinku!',
                  },
                ]}>
                <Input.Password prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0, marginTop: 40 }}>
                <Button loading={loading} block type='primary' htmlType='submit' size='large'>
                  Prijavi se
                </Button>
              </Form.Item>
            </Form>
          </div>
        </BrowserView>
        <MobileView viewClassName='mobileLoginContainer'>
          <img className='loginLogo' alt='Carta Systems' src={cartaMobile} />
          <div className='loginMobile'>Preuzmite našu aplikaciju za vaš Android uređaj!</div>
          <img className='downloadimg' src={download} alt='' />
          <Button onClick={() => window.open("https://demoapi.cartasystems.com/install/android.apk", "noopener noreferrer")}  block size='large' type='primary' icon={<DownloadOutlined />}>
            Preuzmi
          </Button>
        </MobileView>
      </div>
    </div>
  );
};

export default Login;
