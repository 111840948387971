import React, { useCallback, useEffect, useState } from 'react';
import { Col, Collapse, Descriptions, Empty, Row, Space, Spin, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import InventoryPrint from './inventoryPrint';

const Stock = () => {
  const history = useHistory();
  const [stock, setStock] = useState({ data: [], total: 0, volume: 0 });
  const [loading, setLoading] = useState(true);

  const getStock = useCallback(() => {
    setLoading(true);
    api
      .get(`inventory/stock`)
      .then((response) => {
        setStock({
          data: response.data.stock,
          total: response.data.total,
          volume: response.data.volume,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => getStock(), [getStock]);

  const classColumns = [
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
      width: '100%',
    },
    {
      title: 'Trupaca',
      dataIndex: 'total',
      key: 'total',
      width: '250px',
    },
    {
      title: 'Zapremina',
      dataIndex: 'volume',
      key: 'volume',
      width: '400px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
  ];

  return (
    <RouteLayout
      title={<Text>Stanje lagera</Text>}
      descriptions={
        !loading && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Ukupno trupaca</Text>}>
              {stock.total}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupna zapremina</Text>}>
              {`${stock.volume} ${settings.volumeUnit}`}
            </Descriptions.Item>
          </Descriptions>
        )
      }
      extra={<InventoryPrint type='stock' />}
      showBG={false}
      routes={[
        {
          path: '/',
          breadcrumbName: 'Carta',
        },
        {
          breadcrumbName: 'Stanje lagera',
        },
      ]}>
      {!loading ? (
        Object.keys(stock.data).length !== 0 ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {Object.keys(stock.data).map((type, index) => (
              <Collapse accordion bordered={true} key={index}>
                <Collapse.Panel
                  header={
                    <Row>
                      <Col flex='auto'>
                        <Text strong>{type}</Text>
                      </Col>
                      <Col flex='250px'>
                        <Space size='small'>
                          <Text strong>Ukupno trupaca:</Text>
                          <Text>{stock.data[type].total}</Text>
                        </Space>
                      </Col>
                      <Col flex='250px'>
                        <Space size='small'>
                          <Text strong>Ukupna zapremina:</Text>
                          <Text>{`${stock.data[type].volume} ${settings.volumeUnit}`}</Text>
                        </Space>
                      </Col>
                      <Col flex='125px'>
                        <Text
                          type='success'
                          className='cursorPointer'
                          onClick={() =>
                            history.push({
                              pathname: settings.route.stocklist,
                              search: `vrsta=${type.toLowerCase()}`,
                            })
                          }>
                          Vidi listu trupaca
                        </Text>
                      </Col>
                    </Row>
                  }
                  key={index}>
                  <Table
                    key={index}
                    tableLayout='auto'
                    rowClassName='cursorPointer'
                    rowKey='total'
                    dataSource={Object.keys(stock.data[type].classes).map((clas) => {
                      return { ...stock.data[type].classes[clas], class: clas };
                    })}
                    onRow={(row, rowIndex) => {
                      return {
                        onClick: () => {
                          history.push({
                            pathname: settings.route.stocklist,
                            search: `vrsta=${type.toLowerCase()}&klasa=${row.class
                              .toLowerCase()
                              .replace('/', '-')}`,
                          });
                        },
                      };
                    }}
                    columns={classColumns}
                    pagination={false}
                    size='small'
                  />
                </Collapse.Panel>
              </Collapse>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema trupaca na stanju.' />
        )
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default Stock;
