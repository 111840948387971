import React from 'react';
import { Layout, Menu } from 'antd';
import { history } from '../App';
import {
  HistoryOutlined,
  SnippetsOutlined,
  TeamOutlined,
  AppstoreOutlined,
  InfoCircleOutlined,
  TableOutlined,
  PicCenterOutlined,
  SettingOutlined,
  UserOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import cartaBig from '../img/carta.png';
import cartaSmall from '../img/carta-small.png';

import settings from '../settings';
import { useLocation } from 'react-router-dom';

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const location = useLocation();
  return (
    <Sider
      style={{ textAlign: 'center', position: 'relative' }}
      trigger={null}
      collapsible
      collapsed={collapsed}>
      <img className='logo' alt='Carta' src={collapsed ? cartaSmall : cartaBig} />
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={[
          `/${location.pathname.split('/')[1]}`,
          `/${location.pathname.split('/')[1].replace(new RegExp('a$'), 'e')}`,
          `/${location.pathname.split('/')[1]}i`,
        ]}>
        <Menu.Item
          onClick={() => history.push(settings.route.invoices)}
          key={settings.route.invoices}
          icon={<SnippetsOutlined />}>
          Prijemnice
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.suppliers)}
          key={settings.route.suppliers}
          icon={<TeamOutlined />}>
          Dobavljači
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.stock)}
          key={settings.route.stock}
          icon={<AppstoreOutlined />}>
          Stanje lagera
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.trash)}
          key={settings.route.trash}
          icon={<RollbackOutlined />}>
          Vraćeni trupci
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.history)}
          key={settings.route.history}
          icon={<HistoryOutlined />}>
          Istorija lagera
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.sawlists)}
          key={settings.route.sawlists}
          icon={<PicCenterOutlined />}>
          Prerada
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.element)}
          key={settings.route.element}
          icon={<TableOutlined />}>
          Elementi
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.loginfo.replace('/:id?', ''))}
          key={settings.route.loginfo.replace('/:id?', '')}
          icon={<InfoCircleOutlined />}>
          Info. o trupcu
        </Menu.Item>
        {/*<Menu.Item
          onClick={() => history.push(settings.route.users)}
          key={settings.route.users}
          icon={<UserOutlined />}>
          Korisnici
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(settings.route.admin)}
          key={settings.route.admin}
          icon={<SettingOutlined />}>
          Podešavanja
        </Menu.Item> */}
      </Menu>
      <img
        className='logo positionBottom'
        alt='Carta Systems'
        src={collapsed ? cartaSmall : cartaBig}
      />
    </Sider>
  );
};

export default Sidebar;
