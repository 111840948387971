import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Form,
  InputNumber,
  Select,
  message,
  Empty,
  Divider,
  Popconfirm,
  Tooltip,
} from 'antd';
import RouteLayout from '../../components/RouteLayout';
import { LockTwoTone, UnlockTwoTone } from '@ant-design/icons';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import settings from '../../settings';
import EditInvoiceModal from './modals/EditInvoiceModal';
import { useGlobal } from 'reactn';
import EditInvoicePricesModal from './modals/EditInvoicePricesModal';
import InvoicePrint from './InvoicePrint';
import calculateVolume from '../../utils/calculateVolume';
import roundingMethod from '../../utils/roundingMethod';
import useInvoice from '../../socketio/useInvoice';

const Invoice = () => {
  let { id } = useParams();
  const [invoice, setInvoice] = useState({});
  const [companySettings] = useGlobal('settings');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [edit, setEdit] = useState(false);

  const startEdit = (log) => {
    setEdit(log.id);
    form.setFields([
      { name: 'plate', value: log.plate },
      { name: 'length', value: log.length },
      { name: 'radius', value: log.radius },
      { name: 'volume', value: log.volume },
      { name: 'class', value: log.class },
      { name: 'type', value: log.type },
    ]);
    if (lengthInput.current) {
      lengthInput.current.focus();
    }
  };

  const saveEditLog = (values) => {
    let key = 'logEdit';
    message.loading({ content: 'Izmjena trupca...', key });
    api
      .post(`log/edit`, {
        id: edit,
        ...values,
      })
      .then((response) => {
        setEdit(false);
        getInvoice(false);
        form.resetFields();
        message.success({
          content: `Trupac broj ${response.data.result.plate} uspješno izmjenjen!`,
          key,
          duration: 5,
        });
      })
      .catch((error) => {
        let errors = error.response ? error.response.data.errors : null;
        if (errors) {
          errors.forEach((err) =>
            form.setFields([
              {
                name: 'plate',
                errors:
                  err.msg === 'duplicate_plate'
                    ? ['Trupac sa pločicom već postoji.']
                    : ['Nevažeća pločica!'],
              },
            ])
          );
        }
        message.error({
          content: 'Greška pri izmjeni trupca.',
          key,
          duration: 5,
        });
      });
  };

  const getInvoice = useCallback(
    (setload = true) => {
      if (setload) {
        setLoading(true);
      }
      api
        .post(`invoice/get`, { id })
        .then((response) => {
          setLoading(false);
          setInvoice({
            ...response.data,
            logs: response.data.logs.map((log, index) => {
              return { ...log, number: response.data.logs.length - index };
            }),
          });
        })
        .catch((error) => {
          setLoading(false);
          history.push(settings.route.invoices);
          console.log(error);
        });
    },
    [id, history]
  );

  const onUpdate = (event, name, extra) => {
    if (event === 'edit') {
      message.info({
        content: `${name} je upravo izmjenio ovu prijemnicu!`,
        duration: 3,
      });
    } else if (event === 'delete') {
      message.warning({
        content: `${name} je upravo obrisao ovu prijemnicu!`,
        duration: 3,
      });
    } else if (event === 'addlog') {
      message.success({
        content: `${name} je upravo dodao trupac ${extra}.`,
        duration: 3,
      });
    } else if (event === 'editlog') {
      message.info({
        content: `${name} je upravo izmjenio trupac ${extra}.`,
        duration: 3,
      });
    } else if (event === 'deletelog') {
      message.warning({
        content: `${name} je upravo obrisao trupac ${extra}.`,
        duration: 3,
      });
    }
    getInvoice(false);
  };

  useInvoice(id, onUpdate);

  const onDelete = (id) => {
    let key = 'invoiceDeletion';
    message.loading({ content: 'Brisanje prijemnice...', key });
    api
      .post(`invoice/delete`, { id })
      .then((response) => {
        history.push(settings.route.invoices);
        message.success({
          content: `Prijemnica broj ${response.data.result.number} obrisana!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: 'Greška pri brisanju prijemnice.',
          key,
          duration: 5,
        });
      });
  };

  const onDeleteLog = (id) => {
    let key = 'logDeletion';
    message.loading({ content: 'Brisanje trupca...', key });
    api
      .post(`log/delete`, { id })
      .then((response) => {
        getInvoice(false);
        message.success({
          content: `Trupac broj ${response.data.result.plate} obrisan!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: 'Greška pri brisanju trupca.',
          key,
          duration: 5,
        });
      });
  };

  const onLockToggle = (locked) => {
    let key = 'invoiceLock';
    message.loading({
      content: `${locked ? 'Otključavanje' : 'Zaključavanje'} prijemnice...`,
      key,
    });
    setLoading(true);
    api
      .post(`invoice/edit`, {
        id: invoice.id,
        locked: !locked,
      })
      .then((response) => {
        message.success({
          content: `Prijemnica broj ${response.data.result.number} uspješno ${
            locked ? 'otključana' : 'zaključana'
          }!`,
          key,
          duration: 5,
        });
        setInvoice({ ...invoice, locked: response.data.result.locked });
        setLoading(false);
      })
      .catch(() => {
        message.error({
          content: `Greška pri ${locked ? 'otključavanju' : 'zaključavanju'} prijemnice.`,
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvoice();
  }, [getInvoice]);
  const [form] = Form.useForm();
  const lengthInput = useRef(null);

  const columns = [
    {
      title: 'Redni broj',
      dataIndex: 'number',
      key: 'number',
      render: (value) => `${value}.`,
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: 'Vrsta',
      dataIndex: 'type',
      key: 'type',
      filters:
        invoice.logs &&
        [...new Set(invoice.logs.map((item) => item.type))].map((type) => {
          return { value: type, text: type };
        }),
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: 'Klasa',
      dataIndex: 'class',
      filters:
        invoice.logs &&
        [...new Set(invoice.logs.map((item) => item.class))].map((clas) => {
          return { value: clas, text: clas };
        }),
      key: 'class',
      onFilter: (value, record) => record.class.indexOf(value) === 0,
    },
    {
      title: 'Broj pločice',
      dataIndex: 'plate',
      key: 'plate',
      sorter: (a, b) => a.plate.replace(/\D/g, '') - b.plate.replace(/\D/g, ''),
    },
    {
      title: `Dužina (${settings.logUnit})`,
      dataIndex: 'length',
      key: 'length',
      sorter: (a, b) => a.length - b.length,
    },
    {
      title: `Prečnik (${settings.logUnit})`,
      dataIndex: 'radius',
      key: 'radius',
      sorter: (a, b) => a.radius - b.radius,
    },
    {
      title: `Zapremina (${settings.volumeUnit})`,
      dataIndex: 'volume',
      key: 'volume',
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: 'Akcije',
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      render: (id, row) => (
        <Space size='small' onClick={(e) => e.stopPropagation()}>
          <Button
            type='link'
            onClick={() =>
              invoice.locked
                ? message.error({
                    content: 'Nije moguće izmjeniti trupac zaključane prijemnice.',
                    duration: 5,
                  })
                : startEdit(row)
            }
            disabled={edit}>
            Izmjeni
          </Button>
          <div>
            <Popconfirm
              title='Da li ste sigurni da želite da obrišete trupac?'
              onConfirm={() =>
                invoice.locked
                  ? message.error({
                      content: 'Nije moguće obrisati trupac zaključane prijemnice.',
                      duration: 5,
                    })
                  : onDeleteLog(id)
              }
              disabled={edit}
              okText='Obriši'
              placement='rightTop'
              okButtonProps={{ danger: true }}
              cancelText='Otkaži'>
              <Button type='link' disabled={edit}>
                Obriši
              </Button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    let key = 'logCreation';
    message.loading({ content: 'Dodavanje trupca...', key });
    api
      .post(`log/add`, {
        plate: values.plate.toString().toUpperCase(),
        invoice: id,
        type: values.type,
        class: values.class,
        length: values.length,
        radius: values.radius,
      })
      .then((response) => {
        message.success({
          content: `Trupac ${response.data.result.plate} uspješno dodan!`,
          key,
          duration: 5,
        });
        form.resetFields(['length', 'radius', 'volume']);
        form.setFields([{ name: 'plate', value: +values.plate.toString().replace(/\D/g, '') + 1 }]);
        if (lengthInput.current) {
          lengthInput.current.focus();
        }
        getInvoice(false);
      })
      .catch((error) => {
        let errors = error.response ? error.response.data.errors : null;
        if (errors) {
          errors.forEach((err) =>
            form.setFields([
              {
                name: 'plate',
                errors:
                  err.msg === 'duplicate_plate'
                    ? ['Trupac sa pločicom već postoji.']
                    : ['Nevažeća pločica!'],
              },
            ])
          );
        }
        message.error({ content: 'Greška pri dodavanju trupca.', key, duration: 5 });
      });
  };

  return invoice.id || loading ? (
    <RouteLayout
      title={
        !loading ? (
          <Row gutter={20} align='middle'>
            <Col>Prijemnica: {invoice.number}</Col>
            <Col>
              {invoice.locked ? (
                <Tooltip title={`Zaključano ${moment(invoice.lockedAt).format(settings.fullDate)}`}>
                  <Tag
                    icon={<LockTwoTone twoToneColor='#585858' />}
                    color='default'
                    className='cursorPointer'>
                    Zaključano
                  </Tag>
                </Tooltip>
              ) : (
                <Tag icon={<UnlockTwoTone twoToneColor='#52C41A' />} color='success'>
                  Otključano
                </Tag>
              )}
            </Col>
          </Row>
        ) : (
          'Prijemnica'
        )
      }
      routes={[
        {
          path: settings.route.home,
          breadcrumbName: 'Carta',
        },
        {
          path: settings.route.invoices,
          breadcrumbName: 'Prijemnice',
        },
        {
          breadcrumbName: 'Prijemnica',
        },
      ]}
      extra={
        !loading && [
          <Space direction='horizontal' size={10} key='actions'>
            <InvoicePrint id={invoice.id} locked={invoice.locked} />
            {!invoice.locked && (
              <Popconfirm
                title='Da li ste sigurni da želite da obrišete prijemnicu?'
                onConfirm={() =>
                  invoice.locked
                    ? message.error({
                        content: 'Nije moguće obrisati zaključanu prijemnicu.',
                        duration: 5,
                      })
                    : onDelete(id)
                }
                okText='Obriši'
                placement='rightTop'
                okButtonProps={{ danger: true }}
                cancelText='Otkaži'>
                <Button type='secondary'>Obriši</Button>
              </Popconfirm>
            )}
            <Popconfirm
              title={`Da li ste sigurni da želite da ${
                invoice.locked ? 'otključate' : 'zaključate'
              } prijemnicu?${!invoice.locked ? ' Nećete više moći mijenjati prijemnicu.' : ''}`}
              onConfirm={() => onLockToggle(invoice.locked)}
              okText={invoice.locked ? 'Otključaj' : 'Zaključaj'}
              placement='rightTop'
              okButtonProps={{ danger: true }}
              cancelText='Otkaži'>
              <Button type='secondary'>{invoice.locked ? 'Otključaj' : 'Zaključaj'}</Button>
            </Popconfirm>
            {!invoice.locked && (
              <EditInvoiceModal invoice={invoice} onOk={getInvoice}>
                <Button type='secondary'>Izmjeni</Button>
              </EditInvoiceModal>
            )}
            <EditInvoicePricesModal invoice={invoice} onOk={getInvoice}>
              <Button type='primary'>Rezime i cijene</Button>
            </EditInvoicePricesModal>
          </Space>,
        ]
      }
      descriptions={
        !loading && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Dobavljač</Text>}>
              <Text
                type='success'
                className='cursorPointer'
                onClick={() =>
                  invoice.supplier &&
                  history.push(settings.route.supplier.replace(':id', invoice.supplier.id))
                }>
                {invoice.supplier ? invoice.supplier.name : '-'}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Sastavio</Text>}>
              {invoice.composer ? invoice.composer.name : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Mjerio</Text>}>
              {invoice.measurer ? invoice.measurer.name : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Datum</Text>}>
              {invoice.date && moment(invoice.date).format(settings.date)}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupno trupaca</Text>}>
              {`${invoice.total}`}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupna zapremina</Text>}>
              {`${invoice.totalvolume} ${settings.volumeUnit}`}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Cijena</Text>}>
              {`${invoice.totalprice} ${settings.currency}`}
            </Descriptions.Item>
          </Descriptions>
        )
      }>
      <Form
        layout='vertical'
        size='middle'
        requiredMark={false}
        onValuesChange={(_, values) =>
          form.setFields([
            {
              name: 'volume',
              value: roundingMethod(calculateVolume(values.length, values.radius)),
            },
          ])
        }
        onFinish={edit ? saveEditLog : onFinish}
        form={form}
        style={{ padding: '20px 20px 10px 20px' }}>
        <Row gutter={10} align='bottom'>
          <Col flex='1 1'>
            <Form.Item
              label='Broj pločice'
              name='plate'
              rules={[
                {
                  required: true,
                  message: 'Unesite broj pločice.',
                },
              ]}>
              <Input
                autoFocus
                disabled={invoice.locked}
                placeholder='Unesite broj pločice'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col flex='1 1'>
            <Form.Item
              label={`Dužina trupca (${settings.logUnit})`}
              name='length'
              rules={[
                {
                  required: true,
                  message: 'Unesite dužinu trupca.',
                },
              ]}>
              <InputNumber
                disabled={invoice.locked}
                ref={lengthInput}
                placeholder='Unesite dužinu trupca'
                min={1}
                max={2000}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col flex='1 1'>
            <Form.Item
              label={`Prečnik trupca (${settings.logUnit})`}
              name='radius'
              rules={[
                {
                  required: true,
                  message: 'Unesite prečnik trupca.',
                },
              ]}>
              <InputNumber
                disabled={invoice.locked}
                placeholder='Unesite prečnik trupca'
                min={1}
                max={1000}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col flex='1 1'>
            <Form.Item label='Zapremina' name='volume' initialValue={0}>
              <InputNumber
                disabled={invoice.locked}
                placeholder='Zapremina'
                tabindex='-1'
                readOnly
                formatter={(value) => `${value} ${settings.volumeUnit}`}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col flex='1 1'>
            <Form.Item
              label='Vrsta trupca'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'Odaberite vrstu trupca.',
                },
              ]}>
              <Select
                disabled={invoice.locked}
                placeholder='Odaberite vrstu trupca'
                loading={!companySettings.types}
                options={
                  companySettings.types &&
                  companySettings.types.map((type) => {
                    return { value: type, label: type };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col flex='1 1'>
            <Form.Item
              label='Klasa trupca'
              name='class'
              rules={[
                {
                  required: true,
                  message: 'Odaberite klasu trupca.',
                },
              ]}>
              <Select
                disabled={invoice.locked}
                placeholder='Odaberite klasu trupca'
                loading={!companySettings.classes}
                options={
                  companySettings.classes &&
                  companySettings.classes.map((clas) => {
                    return { value: clas, label: clas };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {edit ? (
                <Space direction='horizontal' size='small'>
                  <Button
                    loading={loading}
                    type='secondary'
                    onClick={() => {
                      setEdit(false);
                      form.resetFields();
                    }}>
                    Otkaži izmjenu
                  </Button>
                  <Button loading={loading} type='primary' htmlType='submit'>
                    Izmjeni
                  </Button>
                </Space>
              ) : (
                <Button
                  loading={loading}
                  type='primary'
                  htmlType='submit'
                  disabled={invoice.locked}>
                  Dodaj trupac
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        tableLayout='fixed'
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema trupaca.' />,
        }}
        rowKey='id'
        loading={loading}
        dataSource={invoice.logs}
        columns={columns}
        pagination={false}
        size='small'
      />
    </RouteLayout>
  ) : (
    <>
      <Divider />
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Prijemnica ne postoji.' />
      <Divider />
    </>
  );
};

export default Invoice;
